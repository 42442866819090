angular.module('pl-shared')

  .factory('Policy', function(apiV2, DS) {
    var Policies = DS.defineResource({
      name: 'policies',
      endpoint: '/v3/policies',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        check: {
          method: 'POST',
          response: apiV2.deserialize
        },
        check_principal: {
          method: 'POST',
          response: apiV2.deserialize
        }
      }
    })

    Policies.topDownOrgAccess = function(descendentOrgId) {
      return this.check(null, { data: {
        resource: {
          id: descendentOrgId,
          type: 'organization'
        },
        actions: [
          'top_down_org_access'
        ]
      } })
    }

    return Policies
  })
